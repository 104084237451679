import React from "react";
import { graphql } from "gatsby";
import { get } from "lodash";
import Layout from "../layouts/layout";
import { RichText } from "prismic-reactjs";
import SEO from "../components/Seo";
import SliceZone from "../components/SliceZone";
import CtaBlock from "../components/CtaBlock";

const useCasePage = ({ data }) => {
  const page = get(data, "prismic.allUse_cases.edges[0].node");
  if (!page) return null;

  return (
    <Layout>
      <SEO title={RichText.asText(page.page_title)} />
      <SliceZone sliceZone={page.body} trackPage={page.page_title} />
      <CtaBlock cta="Contact Us" ctalink="mailto:support@jeevz.com">
        Questions? Talk to our concierge team today.
      </CtaBlock>
    </Layout>
  );
};

export default useCasePage;

export const query = graphql`
  query UseCasesQuery($uid: String!) {
    prismic {
      allUse_cases(uid: $uid) {
        edges {
          node {
            _meta {
              uid
            }
            page_title
            body {
              ... on PRISMIC_Use_caseBodyHeader_block {
                type
                label
                primary {
                  intro__copy
                  page_header
                  header_background
                }
              }
              ... on PRISMIC_Use_caseBodyFeature_grid {
                type
                label
                primary {
                  headline
                }
                fields {
                  feature_description
                  feature_icon
                  feature_title
                }
              }
              ... on PRISMIC_Use_caseBodyLifestyle_image {
                type
                label
                primary {
                  headline
                  jumbo_image
                }
              }
              ... on PRISMIC_Use_caseBodyFeature_strip {
                type
                label
                fields {
                  feature_description
                  feature_icon
                  feature_title
                }
              }
              ... on PRISMIC_Use_caseBodyFeature_picker__grid {
                type
                label
                primary {
                  headline
                }
                fields {
                  feature {
                    ... on PRISMIC_Feature_tile {
                      feature_icon
                      feature_title
                      feature_description
                    }
                  }
                }
              }
              ... on PRISMIC_Use_caseBodyFeature_picker__strip {
                type
                label
                fields {
                  feature {
                    ... on PRISMIC_Feature_tile {
                      feature_icon
                      feature_title
                      feature_description
                    }
                  }
                }
              }
              ... on PRISMIC_Use_caseBodyCovid {
                type
                label
              }
            }
          }
        }
      }
    }
  }
`;
